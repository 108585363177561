:root {
  --yellowOrange: linear-gradient(92.97deg, #ffdc5e 0.13%, #fcc601 99.87%);
  --blueish: linear-gradient(90deg, #3300ff 0%, #4ee8ff 100%);
}

.connectButton {
  border-radius: 10px;
  background: var(--blueish);
  color: white;
  font-weight: bold;
  min-width: 135px;
  height: 44px;
  border: none;
}

.yellowButton {
  background: var(--yellowOrange);
}

.blueishButton {
  background: var(--blueish);
  color: white;
}

.allocationRow__button {
  font-weight: 600;
  border-radius: 10px;
  width: 10rem;
  height: 44px;
}

.buttonDisabled {
  cursor: default;
  pointer-events: none;
}

.closeButton {
  border: 1px solid #fcc601;
  border-radius: 10px;
  margin: 20px 20px 20px 0;
  width: 8rem;
}

.closeButton:hover {
  color: white;
}

.logoutButton {
  color: white;
  border-radius: 10px;
  border-color: white;
  margin-top: 3rem;
  float: right;
}

@media only screen and (max-width: 768px) {
  .optinButton {
    font-size: 1rem;
    width: 10rem;
    padding: 0.4rem 1rem;
    margin: 25px 15px 10px 0;
  }
}
