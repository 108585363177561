.landingPage {
  /* background-image: url("../../assets/background.mp4"); */
  background-size: 100%;
  background-repeat: no-repeat;
  word-wrap: break-word;
  min-width: 100%;
  min-height: 100%;
  min-height: 100vh;
  overflow: auto;
  background: rgb(0, 0, 0, 0.6);
}

.landingPage > video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -100000;
}

.landingVideo {
  padding: 15px;
  text-align: center;
  /* yha pe border */
}

.landingVideo > video {
  width: 70%;
  border-radius: 1rem;
}

.landingPage__video_container {
  display: flex;
  flex-direction: column;
  padding: 30px;
  justify-content: center;
  align-items: center;
  /* border: ; */
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  margin-right: 3rem;
}

.header__logo > img {
  width: 100px;
}

.afkHeading {
  font-family: "Fugaz One";
  color: #ffcc18;
}
.afkHeading > div:first-child {
  margin-bottom: -10%;
  paint-order: stroke fill;
}

.middleContainer {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  margin: 1% 6rem;
}

.accountInfo__address {
  display: flex;
  border-radius: 30px;
  border: 1px solid #ffcc18;
  padding: 3px 8px;
  box-sizing: border-box;
  margin-bottom: 30px;
  background-color: rgba(0, 0, 0, 0.3);
}

.accountInfo__address > p {
  margin: 10px 10px;
  font-weight: 600;
}

.accountInfo__rewardsBalance > div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.whiteBold {
  color: white;
  font-weight: 600;
}

.middleContainer__claimNFT {
  width: 38.4375rem;
  border-radius: 28px;
  background-color: rgba(255, 255, 255, 0.15);
  border: 1px solid rgb(168, 166, 166);
  backdrop-filter: blur(22px);
  padding: 2rem;
}

.middleContainer__claimNFT > h3 {
  color: #ffffff;
  font-weight: 600;
  font-size: 24px;
}

.middleContainer__claimNFT__accountNotConnected > div {
  padding: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  margin-top: 1rem;
  color: rgba(255, 255, 255, 0.3);
}

.middleContainer__claimNFT__notEligible > p {
  color: #ff6161;
  font-weight: 600;
}

.middleContainer__claimNFT__allocations {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-height: 220px;
  overflow: auto;
  padding-right: 20px;
}

.middleContainer__claimNFT__allocations::-webkit-scrollbar {
  width: 2px;
}

.middleContainer__claimNFT__allocations::-webkit-scrollbar-track {
  background-color: transparent;
}

.middleContainer__claimNFT__allocations::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
}

.allocationRow {
  color: white;
  font-weight: 500;
  margin-bottom: 1rem;
}

.allocationRow__imageContainer {
  position: relative;
  text-align: center;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.88) 100%
  );
  width: 160px;
  height: 160px;
  border-radius: 12px;
  margin-bottom: 0.5rem;
}

.allocationRow__imageContainer > img,
.allocationRow__imageContainer > video {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: fill;
  z-index: -1;
  border-radius: 12px;
}

.allocationRow__imageContainer > div {
  position: absolute;
  bottom: 0px;
  left: 1%;
}

.allocationRow__imageContainer > div > p {
  margin: 0;
}

.tokenCountContainer {
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  border-radius: 20px;
  padding: 1rem;
}
.tokenCountContainer > p {
  font-weight: 600;
  color: rgb(216, 216, 216);
  font-size: 1.2rem;
}

.walletChooseModal {
  background-color: #1f2333;
  border-radius: 20px;
  width: 42rem;
}

.walletChooseModal__body {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.walletName {
  border: 1px solid rgba(255, 255, 255, 0.2);
  width: 281px;
  height: 231px;
  border-radius: 20px;
  box-sizing: border-box;
  margin: 1.5rem;
  filter: brightness(0.8);
}

.walletName:hover,
.walletLogo {
  filter: brightness(1.4);
  cursor: pointer;
}

.walletName:hover {
  border: 1px solid white;
}

.walletName > div {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.walletLogo {
  margin-bottom: 10px;
  width: 170px;
}

.modal-header {
  border-bottom: 0 none;
  margin: 0 0 0 5%;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .header {
    margin-right: 0rem;
  }
  .middleContainer {
    margin: auto;
    padding-top: 1rem;
  }
  .walletChooseModal {
    border-radius: 20px;
    width: 22rem;
  }
  .walletName {
    width: 150px;
    margin: 0 0 2rem;
    height: 150px;
  }
  .walletName > div > p {
    font-size: 13px;
  }
  .walletLogo {
    width: 100px;
  }
  .middleContainer__accountInfo {
    margin-top: 1rem;
    margin-left: 2rem;
  }
  .middleContainer__claimNFT {
    width: 85%;
    margin: 2rem auto auto auto;
  }

  .middleContainer__claimNFT__allocations {
    padding: 0;
    max-height: 100%;
  }
  .allocationRow {
    width: 100%;
  }
  .allocationRow__imageContainer {
    width: 100%;
  }

  .allocationRow__imageContainer > img {
    object-fit: cover;
  }

  .allocationRow__imageContainer > div {
    left: 30%;
  }

  .allocationRow__button {
    width: 100%;
  }

  .landingVideo {
    text-align: center;
    margin-top: 4rem;
    /* yha pe border */
  }

  .landingVideo > video {
    width: 100%;
    border-radius: 1rem;
  }
}
